import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import ArticleCards from '../../components/ArticleCards'
import NoResultsText from '../../components/NoResultsText'

const News = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulArticle(
        filter: { category: { title: { ne: "Forms + Feedback" } } }
        sort: { fields: [featured, publishDate], order: [DESC, DESC] }
      ) {
        edges {
          node {
            ...ArticlesQuery
          }
        }
        totalCount
      }
    }
  `)

  const allArticles = data.allContentfulArticle.edges

  return (
    <>
      {allArticles.length ? (
        <ArticleCards articles={allArticles} />
      ) : (
        <NoResultsText>We haven't added any news yet 😢</NoResultsText>
      )}
    </>
  )
}

export default News
